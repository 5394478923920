import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import moment from 'moment'

import useArrayUtils from '../../../../../../hooks/useArrayUtils'
import { SectionNoteText } from '../../../fieldComponents'
import EmptyTableRow from '../../generic/EmptyTableRow'
import Questions from './Questions'

export default function SocialHistoryBody({ values }) {
    const { isArrayEmpty } = useArrayUtils()

    const observations = values.observations

    return (
        <Grid container spacing={2}>
            <SectionNoteText>
                Items retrieved from 10 years previous
            </SectionNoteText>

            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell colSpan={3}>
                                Tobacco
                            </TableCell>
                            <TableCell colSpan={2}>
                                Alcohol
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>History of Tobacco</TableCell>
                            <TableCell>Cigs per day</TableCell>
                            <TableCell>Years smoking</TableCell>
                            <TableCell>History of Alcohol</TableCell>
                            <TableCell>Units per week</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isArrayEmpty(observations) ? (
                            <EmptyTableRow
                                colSpan={6}
                            />
                        ) : observations.map((observation, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ fontWeight: 600 }}>{moment(observation.observationDateTime).format('DD/MM/yyyy')}</TableCell>
                                <RenderField>{observation.historyOfTobaccoUse}</RenderField>
                                <RenderField>{observation.cigarettesSmokedPerDay}</RenderField>
                                <RenderField>{observation.yearsSmoking}</RenderField>
                                <RenderField>{observation.historyOfAlcoholUse}</RenderField>
                                <RenderField>{observation.unitOfAlcoholPerWeek}</RenderField>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Questions />
        </Grid>
    )
}

function RenderField({ children }) {
    let value = null

    // Some values will be null so we need to check for this before rendering
    if (children != null) {
        value = children.value
    }

    return (
        <TableCell>{value}</TableCell>
    )
}