import { useRecoilValue } from 'recoil'
import Grid from '@mui/material/Grid'

import { requestAtom } from '../../../../requestState'
import { InsurerNote, SectionNoteText } from '../../../fieldComponents'
import Expander from '../../../fieldComponents/expander/Expander'
import Questions from './sections/Questions'
import ClinicalExams from './sections/ClinicalExamination'
import GenericSection from './sections/generic/GenericSection'
import AdditionalInformation from './sections/additionalInformation/AdditionalInformation'
import SocialWelfareCerts from './sections/SocialWelfareCerts'


export default function GeneralHistoryBody({ values }) {
    // Get request context to access insurer note for request
    const request = useRecoilValue(requestAtom)

    return (
        <Grid container spacing={2}>
            <SectionNoteText>
                The electronic report will only look to retrieve consultation notes over the past 10 years and past 5 years for medications and lab studies.
                However, the system allows you to exclude any items you feel are not relevant.
            </SectionNoteText>

            {/* Output insurer notes */}
            {request.insurerNotes && (
                <InsurerNote note={request.insurerNotes} />
            )}

            {/* Output custom questions (when data cannot be supplied by PMS system) */}
            <Questions />

            {/* Render each section */}

            {/* Include Clinical Exams under general history tab */}
            <Expander header="Clinical Exams" binding="clinicalExaminations">
                <ClinicalExams exams={values.clinicalExaminations} />
            </Expander>

            <Expander header="Active Conditions" binding="historyOfPresentIllness">
                <GenericSection
                    binding='historyOfPresentIllness'
                    data={values.historyOfPresentIllness}
                />
            </Expander>

            <Expander header="All Attendances" binding="historyOfPastIllness">
                <GenericSection
                    binding='historyOfPastIllness'
                    data={values.historyOfPastIllness}
                />
            </Expander>

            <Expander header="Additional Information" binding="additionalRelevantInformation">
                <AdditionalInformation data={values.additionalRelevantInformation} />
            </Expander>

            {/* Include Social Welfare under general history tab */}
            <Expander header="Social Welfare Certs" binding="socialWelfareCerts">
                <SocialWelfareCerts data={values.socialWelfareCerts} />
            </Expander>
        </Grid>
    )
}